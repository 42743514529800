<template>
  <div class="w-full mb-4 bg-white rounded-xl shadow-lg text-gray-600">
    <div
      class="px-6 py-2 rounded-lg flex justify-between items-center cursor-pointer p-3"
      @click="toggleAccordion"
      style="border-bottom: gray solid 1px"
    >
      <p class="text-dark text-left"><b>{{title_accordion}}</b></p>
      <span
        class="h-5 w-5 m-2 flex justify-center items-center transition"
        :class="{ 'transform rotate-180': accordionOpen }"
      >
        <img src="@/assets/icons/arrow-down.svg" alt="" />
      </span>
    </div>
    <div v-if="accordionOpen" class="py-3 px-6 pb-6">
      <div v-if="loading" class="flex justify-center items-center">
        <a-spin>
          <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
        </a-spin>
      </div>
      <div 
        class="flex flex-col gap-2"
        v-if="count > 0 || loading"
      >
        <div class="w-full grid grid-cols-1 gap-2">
          <BaseDownloadAttachment
            v-for="(option, index) in attachments"
            label="Anexo de la respuesta final"
            :value="option"
            :file_name="option.file_name"
            :key="index"
          />
        </div>

      </div>
      <div v-else class="text-gray-400 mt-2">No hay archivos adjuntos</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["attachments", "title_accordion"],
 
  data() {
    return {
      accordionOpen: false,
      loading: false,
      count:0
    };
  },
  watch: {
    page: function (newVal, oldVal) {
    },
    attachments: function (newVal, oldVal) {
      console.log('newVal', newVal, 'oldVal', oldVal)
      this.attachments = newVal;
      this.count = this.attachments.length;
    },
  },
  methods: {
    toggleAccordion() {
      this.accordionOpen = !this.accordionOpen;
    }
  },
};
</script>

<style></style>